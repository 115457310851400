.Footer-container{
    position: relative;
    
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding:1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>a>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.fb{
    color:white;
}
.logo-f>img{
    width: 10rem;
}

.logo-f{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}
.logo-f>span{
    color:white;
    opacity: 0.7;
}

.blur-f-1{
    bottom:0;
    right: 22%;
    width: 34rem;
    height: 20rem;
    filter: blur(150px);
    background-color: red;
}
.blur-f-2 {
    bottom: 0;
    left: 22%;
    width: 34rem;
    height: 20rem;
    filter: blur(150px);
    background-color: rbg(255,111,0);
}